<template>
  <v-card>
    <v-toolbar dark color="secondary" flat>
      <v-toolbar-title>Upcoming Schedules</v-toolbar-title>
    </v-toolbar>
    <v-timeline dense v-if="upcoming_meetings.length">
      <v-list
        ><v-timeline-item
          right
          small
          v-for="(meeting, i) in upcoming_meetings.splice(0, 5)"
          color="green lighten-1"
          :key="i"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{
                new Date(meeting.starts_at).toLocaleDateString()
              }}</v-list-item-title>
              <v-list-item-subtitle>
                {{
                  new Date(meeting.starts_at).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                }}
                -
                {{
                  new Date(meeting.ends_at).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                }}</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                link
                :to="{
                  name: 'meeting',
                  params: {
                    lobbyName: meeting.meeting.lobby_name,
                  },
                }"
                ><v-icon>mdi-arrow-top-right</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-timeline-item></v-list
      >
    </v-timeline>
    <v-card-text class="text-center" v-else
      >No Upcoming Schedules Found</v-card-text
    >
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    class_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return { meetings: [] };
  },

  computed: {
    upcoming_meetings() {
      var meetings = this.$store.getters["meetings/upcoming_meetings"];
      return this.class_id
        ? meetings.filter((m) => m.room_subject === this.class_id)
        : meetings;
    },
  },
};
</script>

<style lang="scss"></style>
