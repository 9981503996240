<template>
  <v-autocomplete
    label="Select Class"
    :items="items"
    item-text="subject.title"
    item-value="id"
    @input="handleInput"
    v-model="room_subject"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-html="item.subject.title"></v-list-item-title>
        <v-list-item-subtitle v-html="item.room.name"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      {{ item.subject.title + "-" + item.room.name }}
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  data: () => ({
    room_subject: "",
    items: [],
  }),
  async mounted() {
    await this.loadData();
  },
  methods: {
    loadData() {
      return this.$axios
        .get("/rooms/room-subjects/teacher-room-subjects/")
        .then((response) => {
          this.items = response.data.results;
        });
    },
    handleInput() {
      this.$emit("input", this.room_subject);
    },
  },
};
</script>

<style>
</style>